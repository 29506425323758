"use client";
import Locations from "@/components/Locations";
import { List } from "flowbite-react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { MdCall } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer className="bg-neutral-800">
        <section className="py-10 md:py-16 lg:py-20 text-white">
          <div className="max-w-screen-xl mx-5 lg:mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div>
                <h6 className="font-semibold mb-6">CONTACT US</h6>
                <div>INDIA</div>
                <div className="flex gap-3">
                  <FaLocationDot size={30} />
                  <p>
                    Andheri Industrial Estate, Veera Desai Road, Andheri West,
                    Mumbai.India
                  </p>
                </div>
                <div className="flex gap-2 mt-2">
                  <MdCall size={20} className="self-center" />
                  <p className="">+91 98212 12676</p>
                </div>
                <div className="flex gap-2 mt-2">
                  <IoMdMail size={20} className="self-center" />
                  <p className="">sales1@prateeksha.com</p>
                </div>
                <div className="mt-10">North Carolina, USA</div>
                <div className="flex gap-3">
                  <FaLocationDot size={30} />
                  <p>Old Cherry Point Rd New Bern, Nc 28560. USA.</p>
                </div>
                <div className="flex gap-2 mt-2">
                  <MdCall size={20} className="self-center" />
                  <p className="">+1 252 497 2605</p>
                </div>
                <div className="flex gap-2 mt-2">
                  <IoMdMail size={20} className="self-center" />
                  <p className="">sales1@prateeksha.com</p>
                </div>
              </div>
              <div>
                <h6 className="font-semibold mb-6 ">QUICK LINKS</h6>
                <List
                  unstyled
                  className="md:space-y-2 divide-y divide-dashed divide-opacity-15"
                >
                  <List.Item>
                    <a
                      href="/"
                      className="text-white hover:text-color-orange-400 block"
                    >
                      Home
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/about-us"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      About Us
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/services"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Services
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/cancellation-policy"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Cancellation Policy
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/refund-policy"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Refund Policy
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/terms-and-conditions"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Terms & Conditions
                    </a>
                  </List.Item>
                </List>
              </div>
              <div>
                <h6 className="font-semibold mb-6">SERVICE</h6>
                <List
                  unstyled
                  className="md:space-y-2 divide-y divide-dashed divide-opacity-15"
                >
                  <List.Item>
                    <a
                      href="/webdesign"
                      className="text-white hover:text-color-orange-400 block"
                    >
                      Website Design Services
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/ecommerce"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Ecommerce Website Design
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/search-engine-optimization"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      SEO Services
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/wordpress-customization"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      WordPress Customization
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/website-maintenance"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      Website Maintenance Services
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/psd-to-wordpress-theme"
                      className="text-white hover:text-color-orange-400 pt-2 block"
                    >
                      PSD To WordPress
                    </a>
                  </List.Item>
                </List>
              </div>
              <div>
                <h6 className="font-semibold mb-6">OUR NEWSLETTER</h6>
                <p>Subscribe to my Newsletter, we won't spam. Promise!</p>
                <div className="mt-4">
                  <input
                    type="email"
                    placeholder="Your Email Address..."
                    className="w-full p-2 rounded mb-4 text-gray-800"
                  />
                  <button className="w-full bg-color-orange-500 text-black font-bold p-2 rounded">
                    SUBSCRIBE NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" bg-neutral-900 py-6 md:py-8">
          <div className="max-w-screen-xl mx-5 lg:mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-6 items-center justify-end">
              <div>
                <p className="text-white">
                  © 2024 · Prateeksha Web Design. Built with Next.js All rights
                  reserved
                </p>
              </div>
              <div className="flex self-center space-x-2 p-[4px]">
                <a
                  href="#"
                  className="text-white border-[1px] border-gray-700 p-[4px]"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="#"
                  className="text-white border-[1px] border-gray-700 p-[4px]"
                >
                  <FaTwitter />
                </a>
                <a
                  href="#"
                  className="text-white border-[1px] border-gray-700 p-[4px]"
                >
                  <FaInstagram />
                </a>
                <a
                  href="#"
                  className="text-white border-[1px] border-gray-700 p-[4px]"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
